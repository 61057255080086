import { GET_MENU_SUCCESS, GET_MENU_FAILURE } from '../constants/actionTypes';

const INITIAL_STATE = {
  menus: [],
};

export default function menus(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MENU_SUCCESS:
      return { ...state, menus: action.payload.data };
    case GET_MENU_FAILURE:
      return { ...state, menus: [] };
    default:
      return state;
  }
}
