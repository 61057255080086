import { lazy } from "react";
const Solutions = lazy(() => import("../../components/Solutions"));
const About = lazy(() => import("../../components/About"));
const Studios = lazy(() => import("../../components/Studios"));
const JoinUs = lazy(() => import("../../components/JoinUs"));
const SayHello = lazy(() => import("../../components/SayHello"));
const CmsPage = lazy(() => import("../../components/CmsPage"));
const GetNotified = lazy(() => import("../../components/GetNotified"));
const Platform = lazy(() => import("../../components/Platform"));
const Gimmefy = lazy(() => import("../../components/Gimmefy"));

const commonRoutes = [
  { path: "/pages/:page", element: <CmsPage /> },
  { path: "/solutions", element: <Solutions /> },
  { path: "/about", element: <About /> },
  { path: "/platform", element: <Platform /> },
  { path: "/studios", element: <Studios /> },
  { path: "/join-us", element: <JoinUs /> },
  { path: "/say-hello", element: <SayHello /> },
  { path: "/get-notified", element: <GetNotified /> },
  { path: "/gimmefy", element: <Gimmefy /> },
];

export { commonRoutes };
