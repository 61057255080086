import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/font-awesome.css";
import "./index.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { commonRoutes } from "./store/routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import store from "./store";
import CustomLoading from "./components/CustomLoading";

const NotFoundPage = lazy(() => import("./components/NotFoundPage"));
const HomePage = lazy(() => import("./components/HomePage"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense
      fallback={<CustomLoading />}
    >
      <Provider store={store}>
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<App />} exact>
              <Route index element={<HomePage />} />

              {commonRoutes.map((route) => (
                <Route path={route.path} element={route.element} key={route.path} />
              ))}

              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
