import {
    SET_CATEGORIES_AND_TASKS,
    SET_CATEGORIES,
    SET_CURRENT_PAGE,
    SET_CURRENT_STEP,
    SET_CURRENT_STEP_DATA,
    SET_CURRENT_TASK,
    SET_CURRENT_TASK_DATA,
    SET_PROMPT_TEMPLATES,
    SET_TASKS,
    SET_USER_VALUES,
    SET_IDEA_RESULT,
    SET_SETTINGS_LEVEL,
    RESET_GIMMEFY_STATE
} from '../constants/actionTypes';

const INITIAL_STATE = {
    //
    categoriesAndTasks: [],
    categories: [],
    tasks: [],
    promptTemplates: [],
    //

    currentPage: "Welcome",
    currentTask: 0,
    currentTaskData: {},
    //
    currentStep: 0,
    currentStepData: {},

    userValues: {},

    settingsLevel: 1,

    ideaResult: '',
};

export default function gimmefy(state = INITIAL_STATE, action) {
    switch (action.type) {

        case SET_CATEGORIES_AND_TASKS:
            return { ...state, categoriesAndTasks: action.payload };

        case SET_CATEGORIES:
            return { ...state, categories: action.payload };
        case SET_TASKS:
            return { ...state, tasks: action.payload };
        case SET_PROMPT_TEMPLATES:
            return { ...state, promptTemplates: action.payload };

        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };

        case SET_CURRENT_TASK:
            return { ...state, currentTask: action.payload };
        case SET_CURRENT_TASK_DATA:
            return { ...state, currentTaskData: action.payload };

        case SET_CURRENT_STEP:
            return { ...state, currentStep: action.payload };
        case SET_CURRENT_STEP_DATA:
            return { ...state, currentStepData: action.payload };

        case SET_USER_VALUES:
            return { ...state, userValues: action.payload };

        case SET_IDEA_RESULT:
            return { ...state, ideaResult: action.payload };

        case SET_SETTINGS_LEVEL:
            return { ...state, settingsLevel: action.payload };

        case RESET_GIMMEFY_STATE:
            return { ...state, 
                currentPage: INITIAL_STATE.currentPage,
                currentTask: INITIAL_STATE.currentTask,
                currentStep: INITIAL_STATE.currentStep,
                userValues: INITIAL_STATE.userValues,
                settingsLevel: INITIAL_STATE.settingsLevel,
                ideaResult: INITIAL_STATE.ideaResult,
            };

        default:
            return state;
    }
}
