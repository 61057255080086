import React from "react";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";
import Footer from "./Footer";
import Header from "./Header";
import "./styles.css";

function Layout() {
  return (
    <>
      <Header />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
      <Footer />
    </>
  );
}

export default Layout;
