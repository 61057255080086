import { createAction } from 'redux-actions';
import { getContentsByType } from '../api/contents';
import { GET_CONTENTS_SUCCESS, GET_CONTENTS_FAILURE } from '../constants/actionTypes';

const getContentSuccess = createAction(GET_CONTENTS_SUCCESS);
const getContentFailure = createAction(GET_CONTENTS_FAILURE);

export function getContents(contentType) {
  return dispatch => getContentsByType(contentType)
    .then((json) => {
      dispatch(getContentSuccess({ ...json, contentType }))
    })
    .catch(error => dispatch(getContentFailure(error)))
}