import React from 'react';
import './styles.css';

const CustomLoading = () => {
  return (
    <div className='loading-container'>
        <img src='/images/loading.gif' alt='loading...' />
    </div>
  )
}

export default CustomLoading