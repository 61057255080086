import { createAction } from 'redux-actions';
import { getBannersByCategory } from '../api/banners';

import { GET_BANNERS_SUCCESS, GET_BANNERS_FAILURE } from '../constants/actionTypes';

const getBannerSuccess = createAction(GET_BANNERS_SUCCESS);
const getBannerFailure = createAction(GET_BANNERS_FAILURE);

export function getBanners(category) {
  return dispatch => getBannersByCategory(category)
    .then((json) => {
      dispatch(getBannerSuccess({ ...json, bannerType: category }))
    })
    .catch(error => dispatch(getBannerFailure(error)))
}
