import Layout from "./components/Layout";
import { ToastContainer } from 'react-toastify';
import React from "react";
import CustomLoading from "./components/CustomLoading";
function App() {
  const [loading, setloading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 2000);
  }, [])
  
  return (
    <>
      {loading ? <CustomLoading /> : <Layout />}
      <ToastContainer draggable={true} theme="colored" />
    </>
  );
}

export default App;
