import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    console.log(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <>
          <style>
            {`
            .went-wrong-sec {
              background: #f8f8f8 ;
            }
            .went-wrong-text {
              color: #005778;
              font-size: 40px;
              padding: 220px 0px 100px;
              font-family: 'ab';
              text-align: center;
              max-width: 600px;
              margin: 0 auto;
              text-transform: uppercase;
            }

            @media (max-width: 476px) {
              .went-wrong-text {
                font-size: 30px;
              }
            }

          `}</style>

          <div className='went-wrong-sec'>
            <div className='container'>
              <h2 className='went-wrong-text' >We are sorry something went wrong!</h2>
            </div>
          </div>
        </>


// style={{ color: '#005778', padding: '180px 0px 80px', fontFamily: 'ah', textAlign: 'center' }}

      );
    }
    return children;
  }
}
