
import {
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILURE
} from '../constants/actionTypes';

const INITIAL_STATE = {
    data: [],
    error: null
};

export default function settings(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_SETTINGS_SUCCESS:
            return { ...state, data: action.payload.data };
        case GET_SETTINGS_FAILURE:
            return { ...state, error: action.payload.error }
        default:
            return state;
    }
}
